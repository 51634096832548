import React from "react"

// Components
import { Footer, Layout, SEO } from "../components/common"

const CaseStudiesPage = () => (
  <Layout>
    <SEO title="Case Studies" />

    <Footer heading="Work with us" />
  </Layout>
)

export default CaseStudiesPage
